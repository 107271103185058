.page-container {
  position: relative;
}

.slider-container {
  line-height: 0;
}

.silder-item {
  width: 100vw;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  img {
    margin: 0 auto;
  }

  &.item1 {
    background-image: url(https://talktan.com/img/girl.jpg);


  }

  &.item2 {
    background-image: url(https://l.talktan.net/assets/images/girl-002.jpg);
  }

  &.item3 {
    background-image: url(https://l.talktan.net/assets/images/girl-003.jpg);
  }

  &.item4 {
    background-image: url(https://l.talktan.net/assets/images/girl-005.jpg);
  }

  &.item5 {
    background-image: url(https://l.talktan.net/assets/images/girl-005.jpeg);
  }

  &.item6 {
    background-image: url(https://l.talktan.net/assets/images/girl-006.jpeg);
  }

  &.item7 {
    background-image: url(https://l.talktan.net/assets/images/girl-007.jpeg);
  }

  &.item1 img {
    margin-top: 60vh;
  }

  &.item2 img,
  &.item3 img,
  &.item4 img,
  &.item5 img,
  &.item6 img {
    margin-top: 10vh;
  }

  &.item7 img {
    margin-top: 5px;
  }
}

.download {
  position: absolute;
  width: 146px;
  height: 46px;
  background: url(https://l.talktan.net/assets/images/download.png) center center no-repeat;
  background-size: cover;
  line-height: 46px;
  bottom: 80px;
  font-size: 18px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 22px;
  margin-left: -73px;
  left: 50%;
  border: none;
  outline: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(255, 0, 0, 0);
}
